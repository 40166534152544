"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MERGE_COMMIT_MESSAGE = exports.DEFAULT_PR_BODY = exports.CMS_BRANCH_PREFIX = void 0;
exports.branchFromContentKey = branchFromContentKey;
exports.contentKeyFromBranch = contentKeyFromBranch;
exports.generateContentKey = generateContentKey;
exports.isCMSLabel = isCMSLabel;
exports.labelToStatus = labelToStatus;
exports.parseContentKey = parseContentKey;
exports.statusToLabel = statusToLabel;
const CMS_BRANCH_PREFIX = 'cms';
exports.CMS_BRANCH_PREFIX = CMS_BRANCH_PREFIX;
const DEFAULT_PR_BODY = 'Automatically generated by Netlify CMS';
exports.DEFAULT_PR_BODY = DEFAULT_PR_BODY;
const MERGE_COMMIT_MESSAGE = 'Automatically generated. Merged on Netlify CMS.';
exports.MERGE_COMMIT_MESSAGE = MERGE_COMMIT_MESSAGE;
const DEFAULT_NETLIFY_CMS_LABEL_PREFIX = 'netlify-cms/';

function getLabelPrefix(labelPrefix) {
  return labelPrefix || DEFAULT_NETLIFY_CMS_LABEL_PREFIX;
}

function isCMSLabel(label, labelPrefix) {
  return label.startsWith(getLabelPrefix(labelPrefix));
}

function labelToStatus(label, labelPrefix) {
  return label.slice(getLabelPrefix(labelPrefix).length);
}

function statusToLabel(status, labelPrefix) {
  return `${getLabelPrefix(labelPrefix)}${status}`;
}

function generateContentKey(collectionName, slug) {
  return `${collectionName}/${slug}`;
}

function parseContentKey(contentKey) {
  const index = contentKey.indexOf('/');
  return {
    collection: contentKey.slice(0, index),
    slug: contentKey.slice(index + 1)
  };
}

function contentKeyFromBranch(branch) {
  return branch.slice(`${CMS_BRANCH_PREFIX}/`.length);
}

function branchFromContentKey(contentKey) {
  return `${CMS_BRANCH_PREFIX}/${contentKey}`;
}